<template>
  <section class="service_item_container" :class="{ reverse }" ref="root">
    <section class="left_container" :class="{ [leftAnimation]: appear }">
      <article class="title">
        {{ $t(prefix) }}<span class="high_light">{{ $t(highLight) }}</span
        >{{ suffix && $t(suffix) }}
      </article>
      <article class="content">
        {{ $t(content) }}
      </article>
      <article class="tags">
        <span v-for="tag in tags" :key="tag" class="tag">{{ $t(tag) }}</span>
      </article>
      <div class="button" @click="connect">
        <span>{{ $t('了解更多') }} <a-image :src="arrow" :preview="false" alt="understand more" /> </span>
      </div>
    </section>
    <section class="right_container" :class="{ [rightAnimation]: appear }">
      <!-- <img
        class="follow_image"
        :src="followImg"
        :preview="false"
        alt="follow_image"
        :class="{ bottom_enter: appear, [followClass]: true }"
      /> -->
      <img :src="img" show-loader :preview="false" class="img" alt="follow_image" />
    </section>
  </section>
</template>

<script setup lang="ts">
import arrow from '@/assets/icons/arrow-blue.svg';
import { isMobile } from '@/common/utils';
import { useIntersectionObserver } from '@vueuse/core';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
interface IServiceProp {
  prefix: string;
  highLight: string;
  color: string;
  suffix: string;
  width: number;
  content: string;
  tags: string[];
  img: string;
  followImg: string;
  followClass: string;
  reverse?: boolean;
}
const root = ref(null);
const appear = ref(false);
// eslint-disable-next-line vue/no-setup-props-destructure
const { reverse, width, highLight } = defineProps<IServiceProp>();
const ownWidth = `${width}px`;
const leftAnimation = reverse ? 'right_enter' : 'left_enter';
const rightAnimation = reverse ? 'left_enter' : 'right_enter';
function Appear() {
  const { stop } = useIntersectionObserver(
    root,
    ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        appear.value = true;
      }
    },
    {
      threshold: 0.1,
    }
  );
}
// onMounted(Appear);

const mobile = ref(false);
onMounted(() => {
  mobile.value = isMobile();
});
const router = useRouter();
function connect() {
  switch (highLight) {
    case '海外雇佣1':
      router.push('/product/oversea-employ');
      return;
    case '灵活用工1':
      router.push('/product/poly-employ');
      return;
    case '薪酬服务1':
      router.push('/product/payroll');
      return;
    case '人才招聘':
      router.push('/product/oversea-recruitment');
      return;
  }
  useLeadsInfo(`首页-${highLight}-了解更多`, 'serviceConsulting');
}
</script>

<style lang="less" scoped>
@media screen and(min-width:801px) {
  .service_item_container {
    min-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left_container {
      width: v-bind(ownWidth);

      // opacity: 0;
      .title {
        font-weight: 600;
        font-size: 36px;
        line-height: 58px;
        color: #000000;

        .high_light {
          color: v-bind(color);
        }
      }

      .content {
        margin-top: 21px;
        font-size: 20px;
        line-height: 38px;
        color: #6e7282;
      }

      .tags {
        margin-top: 40px;
        font-size: 24px;
        line-height: 38px;
        color: #1d2129;

        .tag:not(:last-child) {
          padding-right: 24px;
        }
      }

      .button {
        display: block;
        margin-top: 80px;
        font-weight: 500;
        font-size: 24px;
        color: #165dff;
        cursor: pointer;
      }

      .button:hover {
        color: #4080ff;
      }
    }

    .right_container {
      // opacity: 0;
      position: relative;
      max-height: 680px;

      .img {
        // // width: 100%;
        height: 680px;
        // object-fit: cover;
      }

      .follow_image {
        position: absolute;
        // z-index: 1;
      }

      .follow1 {
        width: 341px;
        // height: 160px;
        // background: rgba(255, 255, 255, 0.95);
        // box-shadow: 6px 9px 29px rgba(201, 201, 201, 0.25);
        border-radius: 20px;
        left: -49px;
        bottom: 115px;
      }

      .follow2 {
        width: 321px;
        // height: 152px;
        // background: rgba(255, 255, 255, 0.95);
        // box-shadow: 4.5px 6.8px 22px rgba(201, 201, 201, 0.25);
        border-radius: 15px;
        right: -88px;
        bottom: 130px;
      }

      .follow3 {
        width: 400px;
        // height: 148px;
        // background: rgba(255, 255, 255, 0.95);
        // box-shadow: 6px 9px 29px rgba(201, 201, 201, 0.25);
        border-radius: 16px;
        left: -61px;
        bottom: 43px;
      }

      .follow4 {
        width: 322px;
        right: -87px;
        bottom: 36px;
      }

      .follow5 {
        width: 311px;
        left: -99px;
        bottom: 46px;
      }
    }
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .ml20 {
    margin-left: 20px;
  }
}

@media screen and(max-width :800px) {
  .service_item_container {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 20px;

    .left_container {
      // opacity: 0;
      .title {
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        color: #000000;

        .high_light {
          color: v-bind(color);
        }
      }

      .content {
        padding-top: 16px;
        line-height: 24px;
        color: #6e7282;
      }

      .tags {
        white-space: nowrap;
        padding-top: 16px;
        font-size: 15px;
        line-height: 38px;
        color: #1d2129;
        display: flex;
        justify-content: space-between;

        span {
          margin-right: 10px;
        }
      }

      .button {
        color: #165dff;
        margin-top: 17px;
        font-weight: 500;
        font-size: 16px;
        padding: 0;
      }
    }

    .right_container {
      // opacity: 0;
      margin-bottom: 89px;
      position: relative;
      height: 360px;

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }

      .follow_image {
        position: absolute;
        // z-index: 1;
      }

      .follow1 {
        width: 240px;
        border-radius: 20px;
        left: 18px;
        bottom: -49px;
      }

      .follow2 {
        width: 240px;
        border-radius: 15px;
        right: 18px;
        bottom: -49px;
      }

      .follow3 {
        width: 280px;
        border-radius: 16px;
        left: 10px;
        bottom: -41px;
      }

      .follow4 {
        width: 240px;
        border-radius: 15px;
        right: 18px;
        bottom: -49px;
      }

      .follow5 {
        width: 280px;
        left: 10px;
        bottom: -41px;
      }
    }
  }
}

.left_enter {
  animation: left-enter 0.8s ease-in-out both;
}

@keyframes left-enter {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.right_enter {
  animation: right-enter 0.8s ease-in-out both;
}

@keyframes right-enter {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.bottom_enter {
  animation: bottom-enter 0.8s ease-in-out both;
}

@keyframes bottom-enter {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
